export const Mensagem = {
	/**--------------- MENSAGENS DE SUCESSO ---------------**/
	SUCESSO: {
		//- Formulários gerais
		REGISTRO_CADASTRADO: "Registro cadastrado com sucesso!",
		REGISTROS_CADASTRADOS: "Registros cadastrados com sucesso!",
		REGISTRO_ADICIONADO: "Registro adicionado com sucesso!",
		REGISTROS_ADICIONADOS: "Registros adicionados com sucesso!",
		REGISTRO_EDITADO: "Registro editado com sucesso!",
		REGISTROS_EDITADOS: "Registros editados com sucesso!",
		REGISTRO_EXCLUIDO: "Registro excluído com sucesso!",
		REGISTROS_EXCLUIDOS: "Registros excluídos com sucesso!",
		PESSOA_FISICA_ENCONTRADA_PELO_CPF: "Pessoa física cadastrada no sistema!",
		ALTERACOES_SALVAS: "Alterações salvas com sucesso!",
		SITUACAO_ALTERADA: (nomeRegistro?: string) => (nomeRegistro ? `Situação do ${nomeRegistro.toLowerCase()} alterada com sucesso` : `Situação alterada com sucesso!`),
		SOCIO_CADASTRADO: "Sócio cadastrado com sucesso!",
		SOCIO_DELETADO: "Sócio deletado com sucesso!",
		SOCIO_EDITADO: "Sócio editado com sucesso!",
		PLANO_AUDITORIA_CADASTRADO: (protocolo?: string): string => `Plano de Auditoria cadastrado com sucesso! ${protocolo}`,
		VINCULACAO_ENVIADA:(protocolo?: string): string => `Vinculação enviada com sucesso! ${protocolo}`,

		//- Solicitações
		SOLICITACAO_INICIADA: (protocolo?: string): string => protocolo ? `Sua solicitação foi iniciada com sucesso! Seu protocolo de acompanhamento foi gerado ${protocolo}` : `Sua solicitação foi iniciada com sucesso!`,
		SOLICITACAO_ENVIADA: (protocolo?: string): string => `Solicitação enviada com sucesso ${protocolo}`,
		SOLICITACAO_ENCAMINHADA: (nomeArea?: string) => (nomeArea ? `Solicitação encaminhada com sucesso para a área: ${nomeArea}!` : "Solicitação atribuida com sucesso!"),
		RASCUNHO_SALVO: "Rascunho salvo com sucesso.",
		OPERACAO_SUCESSO: "Operação realizada com sucesso!",
		COMPLEMENTACAO_SOLICITADA: "Complementação solicitada com sucesso",
		COMPLEMENTACAO_ADICIONADA: "Solicitação de complementação adicionada!",
		COMPLEMENTACAO_EXCLUIDA: "Solicitação de complementação excluída com sucesso!",
		COMPLEMENTACAO_EDITADA: "Solicitação de complementação editada com sucesso!",
		COMPLEMENTACAO_ENVIADA: "Complementação enviada com sucesso",
		ASSINATURA_AGENTE_CERTIFICADO_REGISTRADA: "Assintatura do Agente de Certificado registrada com sucesso!",
		ASSINTATURA_AUTORIDADE_CERTIFICADORA_REGISTRADA: "Assinatura da AC registrada com sucesso!",
		ASSINATURA_AR_REGISTRADA: "Assinatura da AR registrada com sucesso!",
		ANALISE_INICIADA: "Análise iniciada com sucesso",
		ANALISE_REALIZADA: "Análise realizada com sucesso",
		ANALISE_DESPACHO_CGAFI: "Despacho CGAFI enviado com sucesso",
		ANALISE_DESPACHO_DAFN: "Despacho DAFN enviado com sucesso",
		ANALISE_DESPACH_DIRETOR_PRESIDENTE: "Despacho Diretor/Presidente enviado com sucesso",
		PUBLICACAO_DOU: "Publicação DOU enviada com sucesso",
		PARECER_TECNICO: "Parecer enviado com sucesso",
		PROCESSO_DEVOLVIDO_PARA_DAFN: "Processo devolvido para DAFN",
		PROCESSO_TIPO_DOCUMENTO: "Criação de Documento salva com sucesso!",
		PROCESSO_AREA_ATUACAO: "Cadastro da Área de Atuação realizado com sucesso!",
		//- Usuário
		EMPRESA_ATUACAO_SELECIONADA: (cnpj: string, nomeEmpresa: string, cargo?: string): string =>
			cargo ? `Você está como ${cargo}, para a empresa ${nomeEmpresa}, CNPJ: ${cnpj}` : `Você está atuando para a empresa ${nomeEmpresa}, CNPJ: ${cnpj}`,
		NOVA_SENHA_ENVIADA: "Nova senha enviada para o e-mail informado!",

		//- Arquivo
		ARQUIVO_REMOVIDO: (nomeArquivo?: string): string => (nomeArquivo ? `${nomeArquivo} removido com sucesso!` : "Arquivo removido com sucesso!"),
	},

	/**--------------- MENSAGENS DE ERRO ---------------**/
	ERRO: {
		//- Formulários gerais
		CAMPO_OBRIGATORIO: (nomeDoCampo: string): string => (nomeDoCampo ? `É necessário preencher o campo ${nomeDoCampo}!` : "Campo obrigatório"),
		PREENCHA_CAMPOS_OBRIGATORIOS: "Preencha todos os campos obrigatórios!",
		CAMPO_INVALIDO: (nomeDoCampo: string): string => `O campo "${nomeDoCampo}" é inválido!`,
		CAMPO_INVALIDO_VALORES_NUMERICOS: "Este campo permite apenas valores numéricos!",
		CAMPO_INVALIDO_CARACTERES_A_Z: "Este campo permite apenas caracteres de A-Z!",
		REGISTRO_EXISTENTE: "Registro já adicionado!",
		ANEXO_TABELA: "É necessário inserir o anexo na tabela",
		SOCIO_TABELA: "É necessário inserir um Sócio na tabela",
		PESSOA_FISICA_NAO_CADASTRADA: "Pessoa não cadastrada no sistema",
		PRECISO_SALVAR_ALTERACOES_ANTES_DE_EXECUTAR_ACAO: "É preciso salvar as alterações do registro em edição para executar esta ação!",
		INSERIR_REGISTRO: "É necessário inserir o registro na tabela",
		INFORMACOES_PRESTADAS: "É necessário marcar que todas as informações prestadas são verdadeiras",
		ESTAR_DE_ACORDO_COM_DECLARACAO: "É necessário marcar que está de acordo com a declaração",
		CAMPO_DESCRICAO: "É necessário preencher o campo de descrição",
		PLANO_AUDITORIA_NAO_CADASTRADO: "Plano de Auditoria não cadastrado",
		PESQUISAR_REGISTROS: "Não foi possível pesquisar os registros",
		REPRESENTANTE: "É necessário inserir um representante legal",
		DOCUMENTO_OBRIGATORIO: "Marque a opção de Documento Obrigatorio!",
		DOCUMENTO_UNICO: "Marque a opção de Documento Unico!",
		DOCUMENTO_ATIVO: "Marque a opção de Modelo Ativo!",
		PERMITE_DEFERIMENTO: "Marque a opção de Permitir Deferimento!",
		PERMITE_ENCERRAMENTO: "Marque a opção de Permitir Encerramento!!",
		INSERIR_PSS: "Por favor, selecione ao menos uma operação entre Credenciamento e Descredenciamento e escolha ao menos um PSS",
		VINCULACAO: "Por favor, selecione a AC2 que será vinculada",
		RENOVACAO_DOCUMENTO: "Marque a opção de Renovação",

		//- Solicitações
		SOLICITACAO_EXISTENTE_AR: "Já existe solicitação para a Empresa candidata a AR em andamento!",
		SOLICITACAO_EXISTENTE_PSS: "Já existe solicitação para a Empresa candidata a PSS em andamento!",
		EXCLUSAO_NAO_PERMITIDA: "Exclusão não permitida!",
		PESQUISAR_SOLICITACOES: "Não foi possível pesquisar as solicitações!",
		PESQUISAR_AUTORIDADES_REGISTRO: "Não foi possível pesquisar as Autoridades de Registro",
		PESQUISAR_DETALHES_SOLICITACAO: "Não foi possível pesquisar os detalhes da solicitação",
		ATRIBUIR_SOLICITACAO_ANALISTA: "Você precisa atribuir a solicitação a um analista",
		SELECIONE_UM_REPRESENTANTE: "Selecione ao menos um representante!",
		INICIAR_ANALISE: "Não foi possível inciar a análise, tente novamente mais tarde",

		//- Usuários
		USUARIO_SENHA_INVALIDOS: "Usuário e/ou Senha inválidos!",
		SENHA_BLOQUEADA_EXCESSO_TENTATIVAS: "Senha bloqueada por excesso de tantativas!",
		EMAIL_NAO_CADASTRADO: "E-mail não cadastrado no sistema!",
		SENHA_IGUAIS: "Nova Senha e Confirmar Nova Senha devem ser iguais!",
		ACESSO_RESTRITO: "Você não pode acessar esse recurso",

		//- Arquivos
		ARQUIVO_INVALIDO: "Arquivo inválido! Só é aceito PDF com tamanho máximo de 50MB!",
		ARQUIVO_TAMANHO_INVALIDO: "O arquivo deve ter tamanho máximo de 50MB!",
		DELETAR_ARQUIVO: "Ocorreu um erro ao deletar o arquivo!",
		ARQUIVO_JA_SELECIONADO: "Arquivo já selecionado!",
		PROCESSAMENTO_ARQUIVO: "Ocorreu um erro ao processar o arquivo.",
	},

	/**--------------- MENSAGENS DE ALERTA ---------------**/
	ALERTA: {
		NENHUM_REGISTRO_ENCONTRADO: "Nenhum registro encontrado!",
		REGISTRO_EXCLUIDO: "Registro excluído com sucesso!",
		REGISTRO_JA_SELECIONADO: (nomeRegistro?: string) => (nomeRegistro ? `O ${nomeRegistro} já foi selecionado` : "Registro já selecionado"),
		NOTIFICACOES_PENDENTES: "Você possui notificações pendentes! Acesse sua caixa de notificações para visualizar!",
		SELECIONAR_EMPRESA_ATUACAO: "Selecione a empresa de atuação!",
		EMPRESA_ATUACAO_JA_SELECIONADA: `Empresa de atuação já selecionada!`,

		//- Solicitações
		COMPLEMENTACAO_DOCUMENTOS_REQUERIDOS: "É necessário complementar os documentos requeridos",
		//- Arquivos
		ARQUIVO_SUBSTITUIDO: "Arquivo substituído!",
		SELECIONE_ARQUIVO: "Selecione um arquivo.",
	},
};
