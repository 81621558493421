import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "environments/environment";
import { DownloadHelper } from "app/shared/helper/download-helper";
import { Documento } from "app/shared/model/Documento";
import { DocumentoUploadDTO } from "../model/documento-upload-dto";


@Injectable({
	providedIn: "root",
})
export class DocumentoUploadService {
	constructor(private http: HttpClient) {}

	download(documento: Documento): Observable<Blob> {
		return this.http.post<Blob>(`${environment.cadastroApiUrl}/documento-upload/download`, documento, DownloadHelper.header());
	}

	handleDownload(response: any, nomeArquivo: string): any {
		const arquivo = new Blob([response], { type: response.type });
		const blob = window.URL.createObjectURL(arquivo);
		const link = document.createElement("a");

		link.href = blob;
		link.download = nomeArquivo;

		link.dispatchEvent(
			new MouseEvent("click", {
				bubbles: true,
				cancelable: true,
				view: window,
			})
		);

		// time out para firefox
		setTimeout(() => {
			window.URL.revokeObjectURL(blob);
			link.remove();
		}, 100);
	}

	buscaDocumentosSolicitacao(protocolo: string): Observable<DocumentoUploadDTO[]> {
		return this.http.get<DocumentoUploadDTO[]>(`${environment.cadastroApiUrl}/documento-upload/documentos-solicitacao?protocolo=${protocolo}`);
	}

}
