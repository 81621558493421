
export const environment = {
    production: true,
  
    keycloakUrl : 'https://sso.iti.gov.br/',
    keycloakRealm : 'p-cubo',
    keycloakClientId: 'cubo-cliente',

    cadastroApiUrl: '/api/cadastro',
    administrativoApiUrl: '/api/administrativo',
    analiseApiUrl: '/api/analise',
    pdfApiUrl: '/api/pdf',
    
    pdfApiUrlEvoSdk: 'https://cubo.iti.gov.br/api/pdf',
    urlGovBr: 'https://sso.acesso.gov.br',

    ANGULAR_PROXY_HOST_API_ADMINISTRATIVO: 'https://cubo.iti.gov.br',
    ANGULAR_PROXY_HOST_API_ANALISE: 'https://cubo.iti.gov.br',
    ANGULAR_PROXY_HOST_API_CADASTRO: 'https://cubo.iti.gov.br',
    ANGULAR_PROXY_HOST_API_PDF: 'https://cubo.iti.gov.br',
};
