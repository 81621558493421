import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { AnaliseTecnica } from "../model/analise-tecnica";
import { Injectable } from "@angular/core";
import { ComplementacaoDocumentos } from "../model/complementacao-documentos";
import { ParecerTecnico } from "../model/parecer-tecnico";
import { DespachoCgafi } from "../model/despacho-cgafi";
import { DespachoDafn } from "../model/despacho-dafn";
import { DespachoDiretorPresidente } from "../model/despacho-diretor-presidente";
import { NotificacaoDeferimentoSolicitacao } from "../model/notificacao-deferimento";
import { PublicacaoDou } from "../model/publicacao-dou";
import { Utils } from "../util/utils";
import { DetalharCredenciamentoDTO } from "../model/detalhar-credenciamento";

@Injectable({ providedIn: "root" })
export class AnaliseTecnicaResultadoService {
	constructor(private readonly http: HttpClient) {}

	salvarAnaliseTecnica(analise: AnaliseTecnica): Observable<any> {
		return this.http.post(`${environment.cadastroApiUrl}/analise-solicitacao/resultado-cadastrar`, analise);
	}

	salvarComplementacaoDocumento(complementacao: ComplementacaoDocumentos[]): Observable<any> {
		return this.http.post(`${environment.cadastroApiUrl}/analise-solicitacao/complemento-documentacao`, complementacao);
	}

	buscarComplementacaoDocumento(protocolo: string): Observable<any> {
		protocolo = Utils.formatarProtocolo(protocolo);
		return this.http.get(`${environment.cadastroApiUrl}/analise-solicitacao/busca-complemento-documentacao/${protocolo}`);
	}

	salvarComplementacaoDocumentoSolicitante(complementacao: ComplementacaoDocumentos[]): Observable<any> {
		return this.http.post(`${environment.cadastroApiUrl}/analise-solicitacao/complemento-documentacao-solicitante`, complementacao);
	}

	salvarComplementacaoFormularioSolicitante(formulario: DetalharCredenciamentoDTO): Observable<any> {
		return this.http.post(`${environment.cadastroApiUrl}/analise-solicitacao/complemento-formulario-solicitante`, formulario);
	}

	salvarParecertecnico(parecer: ParecerTecnico): Observable<any> {
		return this.http.post(`${environment.cadastroApiUrl}/analise-solicitacao/parecer-tecnico`, parecer);
	}

	salvarDespachoCgafi(despacho: DespachoCgafi): Observable<any> {
		return this.http.post(`${environment.cadastroApiUrl}/analise-solicitacao/despacho-cgafi`, despacho);
	}

	salvarHistoricoParaDevolucaoDeCigafParaAnalista(protocolo: string): Observable<any> {
		return this.http.post(`${environment.cadastroApiUrl}/analise-solicitacao/devolver-para-anlista`, protocolo);
	}

	salvarHistoricoParaDevolucaoDeDafnParaCgafi(protocolo: string): Observable<any> {
		return this.http.post(`${environment.cadastroApiUrl}/analise-solicitacao/devolver-para-cgafi`, protocolo);
	}

	salvarHistoricoParaDevolucaoDeDiretorParaDafn(protocolo: string): Observable<any> {
		return this.http.post(`${environment.cadastroApiUrl}/analise-solicitacao/devolver-para-dafn`, protocolo);
	}

	salvarDespachoDafn(despachoDafn: DespachoDafn): Observable<any> {
		return this.http.post(`${environment.cadastroApiUrl}/analise-solicitacao/despacho-dafn`, despachoDafn);
	}

	salvarDespachoDafnAgente(despachoDafn: DespachoDafn): Observable<any> {
		return this.http.post(`${environment.cadastroApiUrl}/analise-solicitacao/agente-despacho-dafn`, despachoDafn);
	}

	salvarDespachoDafnRepresentante(despachoDafn: DespachoDafn): Observable<any> {
		return this.http.post(`${environment.cadastroApiUrl}/analise-solicitacao/representante-despacho-dafn`, despachoDafn);
	}

	salvarDespachoDafnSolicitacao(despachoDafn: DespachoDafn): Observable<any> {
		return this.http.post(`${environment.cadastroApiUrl}/analise-solicitacao/solicitacao-despacho-dafn`, despachoDafn);
	}

	salvarNotificacaoDeferimento(notificacao: NotificacaoDeferimentoSolicitacao): Observable<any> {
		return this.http.post(`${environment.cadastroApiUrl}/analise-solicitacao/salvar-notificacao-deferimento`, notificacao);
	}

	salvarDespachoDiretorPresidente(despachoDiretorPresidente: DespachoDiretorPresidente): Observable<any> {
		return this.http.post(`${environment.cadastroApiUrl}/analise-solicitacao/despacho-diretor-presidente`, despachoDiretorPresidente);
	}

	salvarPublicacaoDou(publicacaoDou: PublicacaoDou): Observable<any> {
		return this.http.post(`${environment.cadastroApiUrl}/analise-solicitacao/publicacao-dou`, publicacaoDou);
	}
	salvarPublicacaoDouDescredenciamento(publicacaoDou: PublicacaoDou): Observable<any> {
		return this.http.post(`${environment.cadastroApiUrl}/analise-solicitacao/publicacao-dou-descredenciamento`, publicacaoDou);
	}

	devolverDespachoAnalistaTecnico(despacho: ParecerTecnico): Observable<any> {
		return this.http.post(`${environment.cadastroApiUrl}/analise-solicitacao/devolver-despacho-para-analista-tecnico`, despacho);
	}

	devolverDespachoAnalistaCGAFI(despacho: DespachoCgafi): Observable<any> {
		return this.http.post(`${environment.cadastroApiUrl}/analise-solicitacao/devolver-despacho-para-cgafi`, despacho);
	}

	devolverDespachoAnalistaDAFN(despacho: DespachoDafn): Observable<any> {
		return this.http.post(`${environment.cadastroApiUrl}/analise-solicitacao/devolver-despacho-para-dafn`, despacho);
	}
}
