import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { KeycloakAuthGuard, KeycloakService } from "keycloak-angular";

import { NotificationService } from "../../shared/service/notification.service";
import { UsuarioService } from "../../shared/service/usuario.service";

/**
 * Auth Guard do keycloak
 */
@Injectable({
	providedIn: "root",
})
export class AuthGuard extends KeycloakAuthGuard {
	constructor(
		protected readonly router: Router,
		protected readonly keycloakService: KeycloakService,
		private readonly usuarioService: UsuarioService,
		private readonly notificationService: NotificationService
	) {
		super(router, keycloakService);
	}

	async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
		//this.usuarioService.getPerfisKeycloak().subscribe(perfis => console.log(perfis));
		// if (typeof route.data.permissoes !== "undefined") {
		// 	const permissoes: any[] = route.data.permissoes;

		// 	this.usuarioService.getPermissoes(permissoes).subscribe(permissao => {
		// 		if (!permissao) {
		// 			this.notificationService.erro("Você não pode acessar esse recurso");
		// 			return;
		// 		}
		// 	});
		// }

		if (!this.authenticated) {
			await this.keycloakService.login({
				redirectUri: window.location.origin,
			});
		}

		return this.authenticated;
	}
}
