import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { TextMaskModule } from "angular2-text-mask";
import { BlockUIModule } from "ng-block-ui";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { MATERIAL_IMPORTS } from "../shared/material-imports";
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { CardComponent } from "./components/card/card.component";
import { InputCheckboxComponent } from "./components/checkbox/input-checkbox.component";
import { InputDateComponent } from "./components/date/input-date.component";
import { DialogComponentTextarea, DialogDataTextareaDialog } from "./components/dialog-textarea/dialog-textarea.component";
import { DialogComponent, DialogDataDialog } from "./components/dialog/dialog.component";
import { DropdownComponent } from "./components/dropdown/dropdown.component";
import { FileUploadComponent } from "./components/file-upload/file-upload.component";
import { FooterComponent } from "./components/footer/footer.component";
import { InputCepComponent } from "./components/input-cep/input-cep.component";
import { InputCnpjComponent } from "./components/input-cnpj/input-cnpj.component";
import { InputCpfComponent } from "./components/input-cpf/input-cpf.component";
import { InputTelefoneComponent } from "./components/input-telefone/input-telefone.component";
import { InputTextComponent } from "./components/input-text/input-text.component";
import { ListComponent } from "./components/list/list.component";
import { NavbarComponent } from "./components/navbar/navbar.component";
import { InputRadioComponent } from "./components/radio/input-radio.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { SimpleDialogComponent } from "./components/simple-dialog/simple-dialog.component";
import { GeradorAssinadorPdfDialogComponent } from "./components/gerador-assinador-pdf/gerador-assinador-pdf.component";
import { SuperTabelaComponent } from "./components/super-tabela/super-tabela.component";
import { TabelaComponent } from "./components/tabela/tabela.component";
import { TableComponent } from "./components/table/table.component";
import { InputUploadComponent } from "./components/upload/input-upload.component";
import { VersionComponent } from "./components/version/version.component";
import { TemplateComponent } from "./template/template.component";
import { DsInputUploadComponent } from "./components/ds-input-upload/ds-input-upload.component";
import { CuboPipeModule } from "app/shared/pipes/cubo-pipe.module";
import { InputCheckboxTextareaComponent } from "./components/checkbox-dialog-textarea/input-checkbox-dialog-textarea.component";
import { TextareaDialogComponent } from "./components/textarea-dialog/textarea-dialog.component";
import { InputTimeComponent } from "./components/input-time/input-time.component";
import { InputNumberComponent } from "./components/input-number/input-number.component";
import { ResultadoAnaliseTecnicaComponent } from "app/core/components/resultado-analise-tecnica/resultado-analise-tecnica.component";
import { EmitirParecerTecnicoComponent } from "./components/emitir-parecer-tecnico/emitir-parecer-tecnico.component";
import { DespachoCgafiComponent } from "./components/despacho-cgafi/despacho-cgafi.component";
import { DespachoDafnComponent } from "./components/despacho-dafn/despacho-dafn.component";
import { ModalBuscarArComponent } from "./components/modal-buscar-ar/modal-buscar-ar.component";
import { ChipListComponent } from "./components/chiplist/chiplist.component";
import { DespachoDiretorPresidenteComponent } from "./components/despacho-diretor-presidente/despacho-diretor-presidente.component";
import { PublicarSolicitacaoArComponent } from "./components/publicar-solicitacao-ar/publicar-solicitacao-ar.component";
import { GerarOficioModalComponent } from "./components/gerar-oficio-modal/gerar-oficio-modal.component";
import { SelectChiplistComponent } from "./components/select-chiplist/select-chiplist.component";
import { DsPaginacaoComponent } from "./components/ds-paginacao/ds-paginacao.component";

import { ButtonComponent } from "./components/button/button.component";
import { ModalBuscarAtivoComponent } from "./components/modal-buscar-ativo/modal-buscar-ativo.component";
import { InputTituloEleitorComponent } from "./components/input-titulo-eleitor/input-titulo-eleitor.component";
import { HistoricoSolicitacaoComponent } from './components/historico-solicitacao/historico-solicitacao.component';
import { RepresentanteSolicitacaoComponent } from "./components/representantes-solicitacao/representantes-solicitacao.component";
import { ComponenteListaComponent } from './components/componente-lista/componente-lista.component';
import { DsSwitchComponent } from "./components/ds-switch/ds-switch.component";
import { CredenciamentoPSSModalComponent } from "./components/credenciamento-pss-modal/credenciamento-pss-modal.component";
import { DadosEmpresaSolicitanteComponent } from "./components/dados-empresa-solicitante/dados-empresa-solicitante.component";
import { DescredenciamentoPSSComponent } from "./components/descredenciamento-pss/descredenciamento-pss.component";
import { CredenciamentoPSSComponent } from "./components/credenciamento-pss/credenciamento-pss.component";
import { DocumentacaoCredenciamentoDescredenciamentoPssComponent } from "./components/documentacao-credenciamento-descredenciamento-pss/documentacao-credenciamento-descredenciamento-pss.component";
import { ModalDescredenciamentoPssComponent } from "./components/modal-descredenciamento-pss/modal-descredenciamento-pss.component";
import { AnaliseAreaAtuacaoComponent } from './components/analise-area-atuacao/analise-area-atuacao.component';
import { HistoricoDocumentosSolicitacaoComponent } from "./components/historico-documentos-solicitacao/historico-documentos-solicitacao.component";
import { HistoricoSolicitacaoEncaminhamentoComponent } from "./components/historico-solicitacao-encaminhamento/historico-solicitacao-encaminhamento.component";
import { AnaliseAreaAtuacaoModalComponent } from './components/analise-area-atuacao-modal/analise-area-atuacao-modal.component';
import { DsInputUploadFormComponent } from "./components/ds-input-upload-form/ds-input-upload-form.component";

import { MensagemTipoPadraoComponent } from './components/mensagem-tipo-padrao/mensagem-tipo-padrao.component';
import { SolicitacaoEnvioDocumentoAssinaturaComponent } from "./components/solicitacao-envio-documento-assinatura/solicitacao-envio-documento-assinatura.component";
import { CabecalhoSolicitacaoComponent } from './components/cabecalho-solicitacao/cabecalho-solicitacao.component';
@NgModule({
	declarations: [
		FooterComponent,
		VersionComponent,
		NavbarComponent,
		SidebarComponent,
		TemplateComponent,
		CardComponent,
		InputTextComponent,
		InputCnpjComponent,
		DropdownComponent,
		BreadcrumbComponent,
		InputCheckboxComponent,
		InputRadioComponent,
		InputDateComponent,
		ListComponent,
		TableComponent,
		TabelaComponent,
		SuperTabelaComponent,
		SimpleDialogComponent,
		GeradorAssinadorPdfDialogComponent,
		InputUploadComponent,
		InputCpfComponent,
		InputTelefoneComponent,
		InputCepComponent,
		FileUploadComponent,
		DialogComponent,
		DialogDataDialog,
		DialogComponentTextarea,
		DialogDataTextareaDialog,
		DsInputUploadComponent,
		DsInputUploadFormComponent,
		InputCheckboxTextareaComponent,
		TextareaDialogComponent,
		InputTimeComponent,
		InputNumberComponent,
		ResultadoAnaliseTecnicaComponent,
		EmitirParecerTecnicoComponent,
		DespachoCgafiComponent,
		DespachoDafnComponent,
		ModalBuscarArComponent,
		ChipListComponent,
		DespachoDiretorPresidenteComponent,
		PublicarSolicitacaoArComponent,
		GerarOficioModalComponent,
		CredenciamentoPSSModalComponent,
		SelectChiplistComponent,
		DsPaginacaoComponent,
		ButtonComponent,
		ModalBuscarAtivoComponent,
		InputTituloEleitorComponent,
		HistoricoSolicitacaoComponent,
  		ComponenteListaComponent,
  		DsSwitchComponent,
		RepresentanteSolicitacaoComponent,
		DadosEmpresaSolicitanteComponent,
		DescredenciamentoPSSComponent,
		CredenciamentoPSSComponent,
		DocumentacaoCredenciamentoDescredenciamentoPssComponent,
		ModalDescredenciamentoPssComponent,
		CredenciamentoPSSModalComponent,
		HistoricoDocumentosSolicitacaoComponent,
		HistoricoSolicitacaoEncaminhamentoComponent,
  		AnaliseAreaAtuacaoComponent,
    	AnaliseAreaAtuacaoModalComponent,
     	MensagemTipoPadraoComponent,
		SolicitacaoEnvioDocumentoAssinaturaComponent,
		CabecalhoSolicitacaoComponent
  	

	],
	exports: [
		CardComponent,
		InputTextComponent,
		InputCnpjComponent,
		DropdownComponent,
		InputCheckboxComponent,
		InputRadioComponent,
		InputDateComponent,
		ListComponent,
		TableComponent,
		TabelaComponent,
		SuperTabelaComponent,
		SimpleDialogComponent,
		GeradorAssinadorPdfDialogComponent,
		InputUploadComponent,
		InputCpfComponent,
		InputTelefoneComponent,
		InputCepComponent,
		FileUploadComponent,
		DialogComponent,
		DialogDataDialog,
		BreadcrumbComponent,
		DialogComponentTextarea,
		DialogDataTextareaDialog,
		DsInputUploadComponent,
		DsInputUploadFormComponent,
		InputCheckboxTextareaComponent,
		TextareaDialogComponent,
		InputTimeComponent,
		InputNumberComponent,
		ResultadoAnaliseTecnicaComponent,
		EmitirParecerTecnicoComponent,
		DespachoCgafiComponent,
		DespachoDafnComponent,
		ModalBuscarArComponent,
		ChipListComponent,
		DespachoDiretorPresidenteComponent,
		PublicarSolicitacaoArComponent,
		GerarOficioModalComponent,
		CredenciamentoPSSModalComponent,
		DsPaginacaoComponent,
		SelectChiplistComponent,
		ButtonComponent,
		ModalBuscarAtivoComponent,
		InputTituloEleitorComponent,
		HistoricoSolicitacaoComponent,
		ComponenteListaComponent,
		RepresentanteSolicitacaoComponent,
		DsSwitchComponent,
		DadosEmpresaSolicitanteComponent,
		DescredenciamentoPSSComponent,
		CredenciamentoPSSComponent,
		DocumentacaoCredenciamentoDescredenciamentoPssComponent,
		ModalDescredenciamentoPssComponent,
		CredenciamentoPSSModalComponent,
		HistoricoDocumentosSolicitacaoComponent,
		HistoricoSolicitacaoEncaminhamentoComponent,
		AnaliseAreaAtuacaoComponent,
    	AnaliseAreaAtuacaoModalComponent,
		MensagemTipoPadraoComponent,
		SolicitacaoEnvioDocumentoAssinaturaComponent,
		CabecalhoSolicitacaoComponent
		
	],
	imports: [CommonModule, MATERIAL_IMPORTS, RouterModule, CollapseModule, ReactiveFormsModule, FormsModule, TextMaskModule, BlockUIModule, CuboPipeModule],
})
export class CoreModule {}
