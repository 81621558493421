import { getLocaleNumberFormat } from "@angular/common";

export enum StatusCredenciamento {
    EM_PREENCHIMENTO = 'EP',
    AGUARDANDO_ASSINATURA_AR = 'AAAR',
    AGUARDANDO_ASSINATURA_AC = 'AAAC',
    AGUARDANDO_ANALISE = 'AA',
    EM_ANALISE = 'EA',
    AGUARDANDO_COMPLEMENTACAO = 'AC',
    AGUARDANDO_DISTRIBUICAO = 'AD',
    COMPLEMENTACAO_SOLICITADA = 'CS',
    EM_ANALISE_TECNICA = 'EAT',
    EM_ANALISE_CGAFI = 'EACGAFI',
    EM_ANALISE_DAFN = 'EADAFN',
    EM_ANALISE_DIRETOR_PRESIDENTE ='EAADP',
    AGUARDANDO_ANALISE_TECNICA = 'AAT',
    AGUARDANDO_ANALISE_CGAFI = 'AACGAFI',
    AGUARDANDO_ANALISE_DAFN = 'AADAFN',
    AGUARDANDO_DISPACHO_DIRETOR_PRESIDENTE =  'ADDP',
    AGUARDANDO_PUBLICACAO_DOU = 'APD',
    DEFERIDO = "D",
    INDEFERIDO = "I",
    AGUARDANDO_ENVIO_DOCUMENTO_ASSINADO_SOLICITACAO ="AEDA"
} 

