import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { AnaliseAreaAtuacaoComponent } from './core/components/analise-area-atuacao/analise-area-atuacao.component';

import { AuthGuard } from './core/seguranca/auth.guard';

const rotas: Routes = [
	{
		path: "",
		loadChildren: () => import("./modulos/modulos.module").then(value => value.ModulosModule),
	},
	{
		path: "credenciamento",
		loadChildren: () => import("./modulos/credenciamento/credenciamento.module").then(value => value.CredenciamentoModule),
		canActivate: [AuthGuard],
		data: {
			breadcrumb: "Credenciamento",
		},
	},
	{
		path: "parametrizacao",
		loadChildren: () => import("./modulos/parametrizacao/parametrizacao.module").then(value => value.ParametrizacaoModule),
		canActivate: [AuthGuard],
		data: {
			breadcrumb: "Parametrizacao",
		},
	},
	{
		path: "consultar-entidades",
		loadChildren: () => import("./modulos/vinculacao-entidades-certificadoras/vinculacao-entidades-certificadoras.module").then(value => value.VinculacaoEntidadesCertificadorasModule),
		canActivate: [AuthGuard],
		data: {
			breadcrumb: "Consultar Entidades",
		},
	},
	{
		path: "perfis",
		loadChildren: () => import("./modulos/perfil/perfil.module").then(value => value.PerfilModule),
		canActivate: [AuthGuard],
		data: {
			breadcrumb: "Perfis",
		},
	},
	{
		path: "usuarios",
		loadChildren: () => import("./modulos/usuario/usuario.module").then(value => value.UsuarioModule),
		canActivate: [AuthGuard],
		data: {
			breadcrumb: "Usuários",
		},
	},
	{
		path: "agente-registro",
		loadChildren: () => import("./modulos/cadastro-usuario/agente-registro/agente-registro.module").then(value => value.AgenteRegistroModule),
		canActivate: [AuthGuard],
		data: {
			breadcrumb: "Agente de registro",
		},
	},
	{
		path: "agente-certificado",
		loadChildren: () => import("./modulos/cadastro-usuario/agente-certificado/agente-certificado.module").then(value => value.AgenteCertificadoModule),
		canActivate: [AuthGuard],
		data: {
			breadcrumb: "Agente de Certificado",
		},
	},
	{
		path: "autoridade-certificadora",
		loadChildren: () => import("./modulos/autoridade-certificadora/autoridade-certificadora.module").then(value => value.AutoridadeCertificadoraModule),
		canActivate: [AuthGuard],
		data: {
			breadcrumb: "Autoridade Certificadora",
		},
	},
	{
		path: "autoridade-registro",
		loadChildren: () => import("./modulos/autoridade-registro/autoridade-registro.module").then(value => value.AutoridadeRegistroModule),
		canActivate: [AuthGuard],
		data: {
			breadcrumb: "Autoridade de Registro",
		},
	},
	{
		path: "operador",
		loadChildren: () => import("./modulos/cadastro-usuario/operador/operador.module").then(value => value.OperadorModule),
		canActivate: [AuthGuard],
		data: {
			breadcrumb: "Operador",
		},
	},
	{
		path: "administrador",
		loadChildren: () => import("./modulos/cadastro-usuario/administrador/administrador.module").then(value => value.AdministradorModule),
		canActivate: [AuthGuard],
		data: {
			breadcrumb: "Administrador",
		},
	},
	{
		path: "administrador-ar",
		loadChildren: () => import("./modulos/cadastro-usuario/administrador-ar/administrador-ar.module").then(value => value.AdministradorARModule),
		canActivate: [AuthGuard],
		data: {
			breadcrumb: "Administrador AR",
		},
	},
	{
		path: "administrador-pss",
		loadChildren: () => import("./modulos/cadastro-usuario/administrador-pss/administrador-pss.module").then(value => value.AdministradorPssModule),
		canActivate: [AuthGuard],
		data: {
			breadcrumb: "Administrador PSS",
		},
	},
	{
		path: "operador-ac",
		loadChildren: () => import("./modulos/cadastro-usuario/operador-ac/operador-ac.module").then(value => value.OperadorACModule),
		canActivate: [AuthGuard],
		data: {
			breadcrumb: "Operador AC",
		},
	},

	{
		path: "operador-pss",
		loadChildren: () => import("./modulos/cadastro-usuario/operador-pss/operador-pss.module").then(value => value.OperadorPssModule),
		canActivate: [AuthGuard],
		data: {
			breadcrumb: "Operador PSS",
		},
	},

	{
		path: "manutencao",
		loadChildren: () => import("./modulos/manutencao/manutencao.module").then(value => value.ManutencaoModule),
		canActivate: [AuthGuard],
		data: {
			breadcrumb: "Manutenção do Credenciamento",
		},
	},
	{
		path: "manutencao",
		loadChildren: () => import("./modulos/manutencao-ac-1/manutencao-ac-1.module").then(value => value.ManutencaoAC1Module),
		canActivate: [AuthGuard],
		data: {
			breadcrumb: "Manutenção do Credenciamento",
		},
	},

	{
		path: "auditoria",
		loadChildren: () => import("./modulos/auditoria/auditoria.module").then(value => value.AuditoriaModule),
		canActivate: [AuthGuard],
		data: {
			breadcrumb: "Cadastro de auditoria",
		},
	},
	{
		path: "ativos",
		loadChildren: () => import("./modulos/ativos/ativos.module").then(value => value.AtivosModule),
		canActivate: [AuthGuard],
		data: {
			breadcrumb: "Cadastro de auditoria",
		},
	},
	{
		path: "descredenciamento",
		loadChildren: () => import("./modulos/descredenciamento/descredenciamento.module").then(value => value.DescredenciamentoModule),
		canActivate: [AuthGuard],
		data: {
			breadcrumb: "Descredenciamento",
		},
	},
	{
		path: "credenciamento-ac-2",
		loadChildren: () => import("./modulos/credenciamento-ac-2/credenciamento-ac-2.module").then(value => value.CredenciamentoAC2Module),
		canActivate: [AuthGuard],
		data: {
			breadcrumb: "Credenciamento",
		},
	},
	{
		path: "credenciamento-ac-1",
		loadChildren: () => import("./modulos/credenciamento-ac-1/credenciamento-ac-1.module").then(value => value.CredenciamentoAC1Module),
		canActivate: [AuthGuard],
		data: {
			breadcrumb: "Credenciamento AC1",
		},
	},
	{
		path: "credenciamento-pss",
		loadChildren: () => import("./modulos/credenciamento-pss/credenciamento-pss.module").then(value => value.CredenciamentoPSSModule),
		canActivate: [AuthGuard],
		data: {
			breadcrumb: "Credenciamento PSS",
		},
	},
	{
		path: "manutencao-ac-1",
		loadChildren: () => import("./modulos/manutencao-ac-1/manutencao-ac-1.module").then(value => value.ManutencaoAC1Module),
		canActivate: [AuthGuard],
		data: {
			breadcrumb: "Manutenção do Credenciamento AC1",
		},
	},
	{
		path: "manutencao-ac-2",
		loadChildren: () => import("./modulos/manutencao-ac-2/manutencao-ac-2.module").then(value => value.ManutencaoAC2Module),
		canActivate: [AuthGuard],
		data: {
			breadcrumb: "Manutenção do Credenciamento AC2",
		},
	},
	{
		path: "manutencao-pss",
		loadChildren: () => import("./modulos/manutencao-pss/manutencao-pss.module").then(value => value.ManutencaoPSSModule),
		canActivate: [AuthGuard],
		data: {
			breadcrumb: "Manutenção do Credenciamento PSS",
		},
	},
	{
		path: "analise-area-atuacao",
		component: AnaliseAreaAtuacaoComponent,
		canActivate: [AuthGuard],
		data: {
			breadcrumb: "Alteração do nome contratual",
		},
	},
];

@NgModule({
	imports: [
		CommonModule,
		BrowserModule,
		RouterModule.forRoot(rotas, {
			useHash: false,
		}),
	],
	exports: [],
})
export class AppRoutingModule {}
